import React, { useEffect } from "react";
import Aos from "aos"
import "aos/dist/aos.css";
import styles from './Contact.module.css'
import { ReactComponent as Email } from "../../Assests/Svg/email-svgrepo-com.svg";
import { ReactComponent as Location } from "../../Assests/Svg/location-pin-alt-1-svgrepo-com.svg";
import { ReactComponent as Phone } from "../../Assests/Svg/phone-svgrepo-com.svg";
import Text from "../../Components/Text/Text";
import TextArea from "../../Components/TextArea/TextArea";
import useContact from "./hooks/useContact";

export default function Contact() {
    const {
        contact,
        setContact,
        handleSubmit
    } = useContact()
    useEffect(() => {
        Aos.init({ duration: 2000 })
    }, [])
    return (
        <section className={styles.about} id="contact">
            <section data-aos="flip-left" className={styles.contact}>
                <ul className={styles.aboutBox}>
                    <li className={styles.iconBox}>
                        <a href="https://maps.app.goo.gl/HHCrhkbRBEaimHGdA" className={styles.iconBox}>
                            <span className={styles.icon}>
                                <Location />
                            </span>
                            <span className={styles.iconText}>FCT Abuja</span>
                        </a>
                    </li>
                    <li className={styles.iconBox}>
                        <a href="tel:+2349017215873" className={styles.iconBox}>
                            <span className={styles.icon}>
                                <Phone />
                            </span>
                            <span className={styles.iconText}>+234 901 721 5873</span>
                        </a>
                    </li>
                    <li className={styles.iconBox}>
                        <a href="mailto:info@79steps.com" className={styles.iconBox}>
                            <span className={styles.icon}>
                                <Email />
                            </span>
                            <span className={styles.iconText}>info@79steps.com</span>
                        </a>
                    </li>
                </ul>
            </section>
            <section className={styles.qrcode}>
                <h2>Contact Us</h2>
                <Text
                    type="text"
                    name="Full Name"
                    placeholder="Full Name"
                    onChange={(e) => setContact({ ...contact, name: e.target.value })}
                    label="Full Name"
                    value={contact.name}
                />
                <Text
                    type="text"
                    name="Email"
                    placeholder="Email"
                    onChange={(e) => setContact({ ...contact, email: e.target.value })}
                    label="Email"
                    value={contact.email}
                />
                <TextArea
                    type="text"
                    name="Message"
                    placeholder="Your Message"
                    onChange={(e) => setContact({ ...contact, message: e.target.value })}
                    label=""
                    value={contact.message}
                />

                <button className={styles.learnBtn}
                    onClick={(e) => {
                        handleSubmit(e)
                    }}
                >SUBMIT</button>
            </section>
        </section>
    )
}