import React from 'react';
import style from './Footer.module.css';

export default function Footer() {
  // Get the current year
  const currentYear = new Date().getFullYear();

  return (
    <div className={style.footer}>
      <p>Copyright &copy; {currentYear} 79 Steps</p>
    </div>
  );
}
