import React, { useEffect } from "react";
import styles from "./About.module.css";
import Aos from "aos"
import "aos/dist/aos.css";
import { ReactComponent as Sales } from "../../Assests/Svg/cube-svgrepo-com.svg";
import { ReactComponent as Ict } from "../../Assests/Svg/computer-and-people-svgrepo-com.svg";
import { ReactComponent as Construction } from "../../Assests/Svg/construction-worker-svgrepo-com.svg"; 

export default function About() {
  useEffect(() => {
    Aos.init({duration: 2000})
  }, [])
  return (
    <section className={styles.about} id="about">
      <h4 data-aos="fade-right" className={styles.headOne}>Our Valued Services</h4>
      <h3 data-aos="fade-left" className={styles.headTwo}>What We Offer</h3>
      <ul className={styles.services}>

        <li data-aos="fade-right" className={styles.serviceOne}>
          <span className={styles.icon}>
            <Ict />
          </span>
          <h4>Software Development</h4>
          <p>
          In the dynamic landscape of technology, staying ahead requires more than just keeping pace - it demands innovation. At 79 Steps, we specialize in crafting bespoke software solutions tailored to meet the unique needs of our clients..
          </p>
        </li>
        <li data-aos="fade-up" className={styles.serviceOne}>
          <span className={styles.icon}>
            <Construction />
          </span>
          <h4>Real Estate</h4>
          <p>
          In the world of real estate, every step matters. At 79 Steps, we redefine property management with innovation and market insight. Whether residential or commercial, our comprehensive services maximize value for owners and investors. From acquisition to asset management, our experienced team drives growth and long-term success in real estate.
          </p>
        </li>

        <li data-aos="fade-left" className={styles.serviceOne}>
          <span className={styles.icon}>
            <Sales />
          </span>
          <h4>Logistics</h4>
          <p>
          Efficient logistics are the backbone of any successful business. At 79 Steps, we offer comprehensive logistics solutions to streamline your supply chain and enhance operational efficiency. From warehousing and distribution to transportation management and inventory optimization, our tailored logistics services are designed to meet your specific needs and exceed your expectations
          </p>
        </li>
      </ul>
    </section>
  );
}
