import React, { useState, useEffect } from "react";
import Aos from "aos"
import styles from "./Home.module.css";
import Contact from "../Contact/Contact";
import About from "../About/About";
import Footer from "../Footer/Footer";
import { ReactComponent as CovLogo } from "../../Assests/Images/79Steps/79steps.svg";

export default function Home() {

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
      setIsMenuOpen(false); // Close the menu if it's open
    }
  };

  useEffect(() => {
    Aos.init({duration: 2000})
  }, [])

  const [activeLink, setActiveLink] = useState("home");


  return (
    <div className={styles.home}>
      <header className={styles.header}>
        <h1 className={styles.logo}>
        <a className={styles.icon}
        href="#home"
        onClick={() => {
          scrollToSection("home");
          setActiveLink("home");
        }}>
                                <CovLogo />
                            </a>
        </h1>
        <button className={styles.hamburger} onClick={toggleMenu}>
          {isMenuOpen ? "✕" : "☰"}
        </button>
       { isMenuOpen ? 
          <nav className={isMenuOpen ? styles.open : ""}>
           <a
            href="#home"
            className={`${styles.item} ${activeLink === "home" ? styles.activeLink : ""}`}
            onClick={() => {
              scrollToSection("home");
              setActiveLink("home");
            }}

          >
            Home
          </a>
          <a
            href="#about"
            className={`${styles.item} ${activeLink === "about" ? styles.activeLink : ""}`}
            onClick={() => {
              scrollToSection("about");
              setActiveLink("about");
            }}
          >
            About
          </a>
          <a
            href="#contact"
            className={`${styles.item} ${activeLink === "contact" ? styles.activeLink : ""}`}
            onClick={() => {
              scrollToSection("contact");
              setActiveLink("contact");
            }}
          >
            Contact Us
          </a>
          </nav>: 
          <nav data-aos="fade-up" className={styles.nav} >
        <a
            href="#home"
            className={`${styles.item} ${activeLink === "home" ? styles.activeLink : ""}`}
            onClick={() => {
              scrollToSection("home");
              setActiveLink("home");
            }}

          >
            Home
          </a>
          <a
            href="#about"
            className={`${styles.item} ${activeLink === "about" ? styles.activeLink : ""}`}
            onClick={() => {
              scrollToSection("about");
              setActiveLink("about");
            }}
          >
            About
          </a>
          <a
            href="#contact"
            className={`${styles.item} ${activeLink === "contact" ? styles.activeLink : ""}`}
            onClick={() => {
              scrollToSection("contact");
              setActiveLink("contact");
            }}
          >
            Contact Us
          </a>
        </nav>
}
      </header>
      <main>
      <section id="home" className={`${styles.main} scroll-in`} >
          <h2 data-aos="flip-right" className={styles.head}>79 STEPS</h2>
          <p data-aos="fade-right" className={styles.pas}>
          At 79 Steps, we don't just embrace change - we lead it. Join us on this journey of innovation, transformation, and endless possibilities. Together, let's take the next 79 steps towards a brighter future.
          </p>
          <button data-aos="fade-up" className={styles.learnBtn}
          onClick={() => {
            scrollToSection("about");
            setActiveLink("about");
          }}
          >LEARN MORE</button>
        </section>
        <About />
        <Contact />
      </main>
      <Footer />
    </div>
  );
}
