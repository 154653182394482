import './App.css';
import Home from './Pages/Home/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AnimatedCursor from "react-animated-cursor"


function App() {


  return (
    <div className="App">
      <ToastContainer
          pauseOnHover
          hideProgressBar
          autoClose={3000}
          position="top-center"
          closeButton={false}
          toastStyle={{
            color: '#000000',
            background: '#FFFF',
          }}
        />
         <Home />
         <AnimatedCursor
      innerSize={15}
      outerSize={10}
      // color='193, 11, 111'
      color = '104, 132, 246'
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={9}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',
        {
          target: '.custom',
          options: {
            innerSize: 12,
            outerSize: 12,
            color: 'transparent',
            outerAlpha: 0.3,
            innerScale: 0.7,
            outerScale: 5
          }
        }
      ]}
    />
    {/* <AnimatedCursor
  innerSize={8}
  outerSize={35}
  innerScale={1}
  outerScale={2}
  outerAlpha={0}
  hasBlendMode={true}
  innerStyle={{
    backgroundColor: '#00CAF9'
  }}
  outerStyle={{
    border: '3px solid #6884F6'
  }}
/> */}
    </div>
  );
}

export default App;
