import styles from "./Text.module.css";

const Text = ({
    name,
    type,
    value,
    onChange,
    onBlur,
    disabled,
    label
}) => {
  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <input
          type="text"
          name={name}
          value={value}
          placeholder=""
          onChange={onChange}
          onBlur={onBlur}
          disabled={disabled}
        />
        <label htmlFor={name}>{label}</label>
      </div>
    </div>
  );
};

export default Text;
