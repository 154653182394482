import emailjs from 'emailjs-com';
import { useState } from 'react';
import { toast } from 'react-toastify';

function useContact() {
    const [contact, setContact] = useState({
        name: '',
        email: '',
        message: ''
    });

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!contact.name || !contact.email || !contact.message) {
            return toast.error('Complete the required fields');
        } else {
            if (emailRegex.test(contact?.email)) {
                emailjs.send(
                    'service_8v5mp99',
                    'template_zj8mw8r',
                    {
                        from_name: contact.name,
                        reply_to: contact.email,
                        message: contact.message
                    },
                    'neJKovXGpx71qRTqO'
                )
                    .then((response) => {
                        toast.success('Your message was sent. We will get in touch with you soon.');
                        setContact({
                            name: '',
                            email: '',
                            message: ''
                        });
                    })
                    .catch((error) => {
                        toast.error('Failed to send your message. Please try again later.');
                    });
            } else {
                toast.error("Enter a valid email")
            }
        }


    };

    return {
        contact,
        setContact,
        handleSubmit
    };
}

export default useContact;
