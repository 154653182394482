import React from "react";
import styles from "./TextArea.module.css";

const TextArea = ({
    name,
    value,
    placeholder,
    onChange,
    label
}) => {
  return (
    <div className={styles["form-group"]}>
      <div className={styles["form-container"]}>
        <textarea
          type="text"
          name={name}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        ></textarea>
        <label htmlFor={name}>{label}</label>
      </div>
    </div>
  );
};

export default TextArea;
